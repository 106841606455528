import React, { useEffect } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import CreateIcon from '@material-ui/icons/Create'
import SaveIcon from '@material-ui/icons/Save'
import NoteIcon from '@material-ui/icons/Note'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Container, TextField, Grid, Button, Icon } from '@material-ui/core'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Modal from '@material-ui/core/Modal'
import { useDispatch } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import axios from 'axios'
import NavBar from '../../components/NavBar'
import { handlerCreateRoom } from '../../store/scope/action'
import { urlApi } from '../../utils/configs'
import { checkField } from '../../utils/generateFields'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    },
    nested: {
      paddingLeft: theme.spacing(3),
      backgroundColor: '#f0f0f0'
    },
    divInputs: {
      margin: '1rem 0'
    },
    formControl: {
      margin: '0.2rem 0',
      backgroundColor: '#f0f0f0'
    },
    buttonSave: {
      position: 'fixed',
      zIndex: 10,
      borderRadius: 0,
      bottom: 0,
      padding: 20,
      width: '60%',
      right: 0
    },
    buttonAddNote: {
      position: 'fixed',
      zIndex: 10,
      borderRadius: 0,
      bottom: 0,
      padding: 20,
      width: '40%',
      left: 0,
      backgroundColor: '#002884'
    },
    gridPadding: {
      paddingBottom: 90
    },
    listItem: {
      fontSize: '0.9rem'
    },
    paper: {
      width: '80%',
      margin: 'auto',
      marginTop: '30%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2)
    }
  })
)

export default function CreateRoom() {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { state } = useLocation()
  const history = useHistory()
  interface OpenProps {
    [x: number]: any
  }
  interface ItemsProps {
    [x: string]: any
  }
  const classes = useStyles()
  const [open, setOpen] = React.useState<OpenProps>({})
  const [value, setValue] = React.useState(1)
  const [valueFilter, setValueFilter] = React.useState(1)
  const [classRoom, setClassRoom] = React.useState('1')
  const [hours, setHours] = React.useState('regular')
  const [roomName, setRoomName] = React.useState('')
  const [roomNote, setRoomNote] = React.useState('')
  const [roomItems, setItems] = React.useState<ItemsProps>({})
  const [cats, setCats]: any = React.useState([])

  const [openModal, setOpenModal] = React.useState(false)

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    axios
      .get(`${urlApi}/projects/getSheets`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
      .then(function(res) {
        if (res.data.feedback.status === 'success') {
          setCats(res.data.data)
        }
      })
      .catch(function(error) {
        console.log(error)
      })
  }, [])


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const intValue = parseInt((event.target as HTMLInputElement).value)
    let intValueFilter = parseInt((event.target as HTMLInputElement).value)
    if (intValueFilter === 2) {
      intValueFilter = 1
    }
    setValueFilter(intValueFilter)
    setValue(intValue)
  }

  const handleChangeClass = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClassRoom((event.target as HTMLInputElement).value)
  }

  const handleChangeHours = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHours((event.target as HTMLInputElement).value)
  }

  const handleClick = (key: number) => {
    setOpen({
      [key]: !open[key]
    })
  }

  const handlerInput = (e: any, id: any, code: any, type: any) => {
    const codeMerge = `${id}_${code}`
    let valueCheck = e.target.value
    if (type === 'radio') {
      if (valueCheck === roomItems[codeMerge]) {
        valueCheck = ''
      }
    } else {
      valueCheck = valueCheck.toUpperCase()
    }

    setItems({
      ...roomItems,
      [codeMerge]: valueCheck
    })
  }

  const saveProject = () => {
    interface FinalRoomArray {
      cod: string
      name: string
      text: any
      config: string
      pdf: string
    }
    interface FinalRoom {
      name: string
      items: FinalRoomArray[]
    }
    const finalRoom: any = []
    const catsFilter = cats.filter((i: any) => i.cat === valueFilter)
    catsFilter.forEach((c: any) => {
      const catName: FinalRoom = { name: c.name, items: [] }
      c.items.forEach((i: any) => {
        const codeMerge = `${c.name}_${i.cod}`
        if (Object.keys(roomItems).indexOf(codeMerge) > -1) {
          catName.items.push({
            cod: i.cod,
            name: i.name,
            text: roomItems[codeMerge],
            config: i.config,
            pdf: i.pdf
          })
        }
        const codeMerge1 = `${c.name}_${i.cod}-1`
        const codeMerge2 = `${c.name}_${i.cod}-2`
        if (Object.keys(roomItems).indexOf(codeMerge1) > -1) {
          catName.items.push({
            cod: i.cod,
            name: i.name,
            text: `${roomItems[codeMerge1]}#${roomItems[codeMerge2]}`,
            config: i.config,
            pdf: i.pdf
          })
        }
      })
      finalRoom.push(catName)
    })

    if (id) {
      dispatch(
        handlerCreateRoom({
          idProject: id,
          name: roomName,
          notes: roomNote,
          category: value,
          items: finalRoom,
          hours,
          classRoom,
          cb: history
        })
      )
      // history.goBack()
    }
  }

  return (
    <>
      <NavBar />
      <Container fixed>
        <div style={{ margin: '20px 0' }}>
          <Button
            onClick={() => {
              history.goBack()
            }}
            color="primary"
            startIcon={<Icon>arrow_back</Icon>}
          >
            Go Back
          </Button>
        </div>
        <div className={classes.divInputs}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Room Name"
            variant="outlined"
            onChange={e => {
              setRoomName(e.target.value)
            }}
          />
        </div>
        <div className={classes.divInputs}>
          <FormControl fullWidth component="fieldset">
            <FormLabel component="legend">Category</FormLabel>
            <RadioGroup
              aria-label="category"
              name="category1"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel
                className={classes.formControl}
                value={1}
                control={<Radio />}
                label="1"
              />
              <FormControlLabel
                className={classes.formControl}
                value={2}
                control={<Radio />}
                label="2"
              />
              <FormControlLabel
                className={classes.formControl}
                value={3}
                control={<Radio />}
                label="3"
              />
            </RadioGroup>
          </FormControl>
          <div style={{ marginTop: 15 }}>
            <FormControl fullWidth component="fieldset">
              <FormLabel component="legend">Class</FormLabel>
              <RadioGroup
                aria-label="class"
                name="class"
                value={classRoom}
                onChange={handleChangeClass}
              >
                <FormControlLabel
                  className={classes.formControl}
                  value="1"
                  control={<Radio />}
                  label="1"
                />
                <FormControlLabel
                  className={classes.formControl}
                  value="2"
                  control={<Radio />}
                  label="2"
                />
                <FormControlLabel
                  className={classes.formControl}
                  value="3"
                  control={<Radio />}
                  label="3"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div style={{ marginTop: 15 }}>
            <FormControl fullWidth component="fieldset">
              <FormLabel component="legend">Hours</FormLabel>
              <RadioGroup
                aria-label="class"
                name="class"
                value={hours}
                onChange={handleChangeHours}
              >
                <FormControlLabel
                  className={classes.formControl}
                  value="regular"
                  control={<Radio />}
                  label="Regular Hours"
                />
                <FormControlLabel
                  className={classes.formControl}
                  value="after"
                  control={<Radio />}
                  label="After Hours"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <Grid className={classes.gridPadding}>
          {cats
            .filter((i: any) => i.cat === valueFilter)
            .map((c: any) => (
              <div key={c._id}>
                <ListItem
                  button
                  onClick={() => {
                    handleClick(c._id)
                  }}
                >
                  <ListItemIcon>
                    <CreateIcon />
                  </ListItemIcon>
                  <ListItemText primary={c.name} />
                  {open[c._id] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={!!open[c._id]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {c.items
                      .filter(
                        (i: any) => i.showIn === 'both' || i.showIn === hours
                      )
                      .map((i: any) => (
                        <ListItem key={i.cod} className={classes.nested}>
                          <div
                            style={{
                              display: 'flex',
                              width: '100%',
                              flexWrap: 'wrap'
                            }}
                          >
                            <div style={{ width: '100%' }}>
                              <ListItemText
                                classes={{
                                  primary: classes.listItem
                                }}
                                primary={i.name}
                              />
                            </div>
                            {checkField(i.config, c, i, handlerInput, roomItems)}
                          </div>
                        </ListItem>
                      ))}
                  </List>
                </Collapse>
              </div>
            ))}
        </Grid>
      </Container>
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        className={classes.buttonSave}
        startIcon={<SaveIcon />}
        onClick={() => {
          saveProject()
        }}
      >
        SAVE ROOM
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        className={classes.buttonAddNote}
        startIcon={<NoteIcon />}
        onClick={handleOpenModal}
      >
        ADD NOTE
      </Button>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <TextField
            id="outlined-multiline-static"
            fullWidth
            label="Add Notes"
            multiline
            rows={15}
            variant="outlined"
            onChange={e => {
              setRoomNote(e.target.value)
            }}
            value={roomNote}
          />
        </div>
      </Modal>
    </>
  )
}
