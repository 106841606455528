import React from 'react'
import 'date-fns'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import SaveIcon from '@material-ui/icons/Save'
import { Container, TextField, Grid, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import qs from 'qs'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { urlApi, config } from '../../utils/configs'
import NavBar from '../../components/NavBar'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    },
    nested: {
      paddingLeft: theme.spacing(3),
      backgroundColor: '#f0f0f0'
    },
    divInputs: {
      margin: '1rem 0',
      width: '100%'
    },
    divInputsFinal: {
      margin: '2rem 0',
      width: '100%'
    },
    formControl: {
      margin: '0.2rem 0',
      backgroundColor: '#f0f0f0'
    },
    buttonSave: {
      position: 'fixed',
      zIndex: 10,
      borderRadius: 0,
      bottom: 0,
      padding: 20
    },
    gridPadding: {
      paddingBottom: 70
    },
    listItem: {
      fontSize: '0.9rem'
    },
    divFeedback: {
      display: 'flex',
      justifyContent: 'center',
      color: 'darkred',
      border: '1px solid gray',
      borderRadius: 4,
      padding: 10
    }
  })
)

export default function CreateUser() {
  const classes = useStyles()
  const [userName, setUserName] = React.useState('')
  const [userEmail, setUserEmail] = React.useState('')
  const [userPassword, setUserPassword] = React.useState('')
  const [userRePassword, setUserRePassword] = React.useState('')
  const [haveError, setError] = React.useState(false)
  const [haveErrorMsg, setErrorMsg] = React.useState('')
  const [checked, setChecked] = React.useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  const history = useHistory()
  const createUser = () => {
    setError(false)
    if (userPassword !== userRePassword) {
      setErrorMsg('Passwords need to be equals')
      setError(true)

      return false
    }
    if (userPassword === '' || userName === '' || userEmail === '') {
      setErrorMsg('All fields is required')
      setError(true)

      return false
    }
    const data = qs.stringify({
      email: userEmail,
      password: userPassword,
      name: userName,
      isAdmin: checked
    })

    axios
      .post(`${urlApi}/users/register`, data, config)
      .then(function(res) {
        if (res.data.feedback.status === 'success') {
          history.push({
            pathname: '/painel/list-users',
            state: { success: 'success' }
          })
        } else {
          setErrorMsg('E-mail already registered in the system.')
          setError(true)
        }
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  return (
    <>
      <NavBar />
      <Container fixed>
        <h2>Create New User</h2>
        <div className={classes.divInputs}>
          <TextField
            fullWidth
            id="name"
            label="User Name"
            variant="outlined"
            onChange={e => {
              setUserName(e.target.value)
            }}
          />
        </div>
        <div className={classes.divInputs}>
          <TextField
            fullWidth
            id="email"
            label="User E-mail"
            variant="outlined"
            onChange={e => {
              setUserEmail(e.target.value)
            }}
          />
        </div>
        <div className={classes.divInputsFinal}>
          <TextField
            fullWidth
            type="password"
            id="password"
            label="User Password"
            variant="outlined"
            onChange={e => {
              setUserPassword(e.target.value)
            }}
          />
        </div>
        <div className={classes.divInputsFinal}>
          <TextField
            fullWidth
            id="crew"
            type="password"
            label="User Password again"
            variant="outlined"
            onChange={e => {
              setUserRePassword(e.target.value)
            }}
          />
        </div>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleChange}
              name="checked"
              color="primary"
            />
          }
          label="This user is Admin?"
        />
        {haveError && <div className={classes.divFeedback}>{haveErrorMsg}</div>}
      </Container>
      <Grid className={classes.gridPadding}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          className={classes.buttonSave}
          startIcon={<SaveIcon />}
          onClick={() => {
            createUser()
          }}
        >
          CREATE USER
        </Button>
      </Grid>
    </>
  )
}
