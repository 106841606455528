// Describing the different ACTION NAMES available
export const TRY_LOGIN = 'TRY_LOGIN'
export const LOGOUT = 'LOGOUT'
export const GET_USER_INFO = 'GET_USER_INFO'

// Describing the shape of the stepbar slice of state

export interface UserInfoInterface {
  name: string
  email: string
  error?: string
}

export interface UserLoginAction {
  type: typeof TRY_LOGIN
  user: UserInfoInterface
}

export interface UserGetInfoAction {
  type: typeof GET_USER_INFO
  user: UserInfoInterface
}

export type UserActionTypes = UserLoginAction | UserGetInfoAction
