import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import axios from 'axios'
import qs from 'qs'
import {
  ADD_PROJECT,
  CREATE_ROOM,
  GET_PROJECTS,
  UPDATE_ROOM,
  DELETE_PROJECT,
  DELETE_ROOM,
  CLEAN_LOGOUT,
  EDIT_PROJECT
} from './types'
import { AppState } from '../index'
import { urlApi, config } from '../../utils/configs'

function startNewProject(newStepBar: any) {
  return {
    type: ADD_PROJECT,
    payload: newStepBar
  }
}

export const handlerStartNewProject = (
  project: any
): ThunkAction<void, AppState, unknown, Action<string>> => async dispatch => {
  const data = qs.stringify({
    costumer: project.costumer,
    date_job: project.date,
    time_job: project.time,
    lead_tech: project.leadTech
  })

  axios
    .post(`${urlApi}/projects/create`, data, config)
    .then(function(res) {
      if (res.data.feedback.status === 'success') {
        dispatch(startNewProject(res.data))
        project.history.push(
          `/painel/project/${res.data.data._id}/${res.data.data.slug_url}`
        )
      } else {
        dispatch(startNewProject(res.data))
      }
    })
    .catch(function(error) {
      console.log(error)
    })
}


function editProject(newStepBar: any) {
  return {
    type: EDIT_PROJECT,
    payload: newStepBar
  }
}

export const handlerEditProject = (
  project: any
): ThunkAction<void, AppState, unknown, Action<string>> => async dispatch => {
  const data = qs.stringify({
    costumer: project.costumer,
    date_job: project.date,
    time_job: project.time,
    lead_tech: project.leadTech,
    idProject: project.id
  })

  axios
    .post(`${urlApi}/projects/updateProject`, data, config)
    .then(function(res) {
      if (res.data.feedback.status === 'success') {
        dispatch(editProject(res.data))
        project.history.push(
          `/painel/project/${res.data.data._id}/${res.data.data.slug_url}`
        )
      } else {
        dispatch(editProject(res.data))
      }
    })
    .catch(function(error) {
      console.log(error)
    })
}


export function getProjects(changeStepBar: any) {
  return {
    type: GET_PROJECTS,
    payload: changeStepBar
  }
}

export const handlerGetProjects = (): ThunkAction<
  void,
  AppState,
  unknown,
  Action<string>
> => async dispatch => {
  axios
    .get(`${urlApi}/projects/getProjects`, {
      withCredentials: true,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .then(function(res) {
      if (res.data.feedback.status === 'success') {

        return dispatch(getProjects(res.data))
      }

      return dispatch(getProjects([]))
    })
    .catch(function(error) {
      console.log(error)
    })
}

export function createRoom(room: any) {
  return {
    type: CREATE_ROOM,
    meta: room
  }
}

export const handlerCreateRoom = (
  project: any
): ThunkAction<void, AppState, unknown, Action<string>> => async dispatch => {
  const data = qs.stringify({
    idProject: project.idProject,
    name: project.name,
    category: project.category,
    items: project.items,
    hours_type: project.hours,
    classRoom: project.classRoom,
    notes: project.notes
  })

  axios
    .post(`${urlApi}/projects/createRoom`, data, config)
    .then(function(res) {
      if (res.data.feedback.status === 'success') {
        dispatch(createRoom(res.data.data))
        project.cb.push({
          pathname: `/painel/project/${project.idProject}/${res.data.data.slug_url}/edit-room/${res.data.room._id}`,
          state: { room: res.data.room }
        })
      } else {
        dispatch(createRoom(project))
      }
    })
    .catch(function(error) {
      console.log(error)
    })
}

export function updateRoom(changeStepBar: any) {
  return {
    type: UPDATE_ROOM,
    meta: changeStepBar
  }
}

export const handlerUpdateRoom = (
  project: any
): ThunkAction<void, AppState, unknown, Action<string>> => async dispatch => {
  const data = qs.stringify({
    idProject: project.idProject,
    name: project.name,
    category: project.category,
    items: project.items,
    hours_type: project.hours,
    classRoom: project.classRoom,
    idRoom: project.idRoom,
    notes: project.notes
  })

  axios
    .post(`${urlApi}/projects/updateRoom`, data, config)
    .then(function(res) {
      if (res.data.feedback.status === 'success') {
        dispatch(updateRoom(res.data.data))
      } else {
        dispatch(updateRoom(project))
      }
    })
    .catch(function(error) {
      console.log(error)
    })
}

export function deleteProject(changeStepBar: any) {
  return {
    type: DELETE_PROJECT,
    meta: changeStepBar
  }
}

export const handlerDeleteProject = (
  project: any
): ThunkAction<void, AppState, unknown, Action<string>> => async dispatch => {
  const data = qs.stringify({
    idProject: project.idProject
  })
  axios
    .post(`${urlApi}/projects/delete`, data, config)
    .then(function(res) {
      if (res.data.feedback.status === 'success') {
        dispatch(deleteProject(res.data.data))
      } else {
        dispatch(deleteProject(project))
      }
    })
    .catch(function(error) {
      console.log(error)
    })
}

export function deleteRoom(changeStepBar: any) {
  return {
    type: DELETE_ROOM,
    meta: changeStepBar
  }
}

export const handlerDeleteRoom = (
  project: any
): ThunkAction<void, AppState, unknown, Action<string>> => async dispatch => {

  const data = qs.stringify({
    idProject: project.idProject,
    idRoom: project.idRoom
  })

  axios
    .post(`${urlApi}/projects/deleteRoom`, data, config)
    .then(function(res) {
      if (res.data.feedback.status === 'success') {
        dispatch(deleteRoom(res.data.data))
      } else {
        dispatch(deleteRoom(project))
      }
    })
    .catch(function(error) {
      console.log(error)
    })
}

export function cleanLogout() {
  return {
    type: CLEAN_LOGOUT
  }
}

export const handlerCleanLogout = (): ThunkAction<
  void,
  AppState,
  unknown,
  Action<string>
> => async dispatch => {
  dispatch(cleanLogout())
}
