import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import LoginScreen from './components/LoginScreen'
import TheScope from './pages/thescope'

export function PrivateRoute({ component: Component, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={props =>
        document.cookie.search(/_tkpresence/i) !== -1 ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/' }} />
        )
      }
    />
  )
}

function App() {
  return (
    <Switch>
      <PrivateRoute path="/painel" component={TheScope} />
      <Route exact path="/">
        <LoginScreen />
      </Route>
    </Switch>
  )
}

export default App
