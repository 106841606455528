// Describing the different ACTION NAMES available
export const ADD_PROJECT = 'ADD_PROJECT'
export const EDIT_PROJECT = 'EDIT_PROJECT'
export const DELETE_PROJECT = 'DELETE_PROJECT'
export const DELETE_ROOM = 'DELETE_ROOM'
export const CREATE_ROOM = 'CREATE_ROOM'
export const GET_PROJECTS = 'GET_PROJECTS'
export const UPDATE_ROOM = 'UPDATE_ROOM'
export const CLEAN_LOGOUT = 'CLEAN_LOGOUT'

// Describing the shape of the stepbar slice of state

interface FinalRoomArray {
  cod: string
  name: string
  text: any
}

export interface FinalRoom {
  idProject: string
  name: string
  category: string
  items: FinalRoomArray[]
}

export interface ProjectsActions {
  id: string
  costumer: string
  date: string
  time: string
  rooms: FinalRoom[]
}

export interface ProjectState {
  projects: ProjectsActions[]
  selectedProject: ProjectsActions[]
}

interface StartProjectAction {
  type: typeof ADD_PROJECT
  payload: ProjectsActions
}

interface GetProjectAction {
  type: typeof GET_PROJECTS
  payload: ProjectsActions
}

export interface ChangeScope {
  id: number
}

interface ChangeScopeAction {
  type: typeof CREATE_ROOM
  meta: FinalRoom
}

export type ScopeActionTypes =
  | StartProjectAction
  | ChangeScopeAction
  | GetProjectAction
