import React, { useEffect, useState } from 'react'
import { Container, Grid, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import SendIcon from '@material-ui/icons/Send'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { handlerUserTryLogin } from '../../store/user/actions'

const useStyles = makeStyles(theme => ({
  button: {},
  divInputs: {
    margin: '2rem 0'
  },
  divH1: {
    display: 'flex',
    justifyContent: 'center'
  },
  divFeedback: {
    display: 'flex',
    justifyContent: 'center',
    color: 'darkred',
    border: '1px solid gray',
    borderRadius: 4,
    padding: 10
  }
}))

function LoginScreen() {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')
  const result: any = useSelector((state: any) => state)

  useEffect(() => {
    if (document.cookie.search(/_tkpresence/i) !== -1) {
      history.push('/painel')
    }
  })

  function testLogin(email: string, pass: string) {
    if (!email || !pass) {
      return false
    }
    dispatch(handlerUserTryLogin({ email, pass, history }))
  }

  return (
    <Container fixed>
      <Grid>
        <form noValidate autoComplete="on">
          <div className={classes.divH1}>
            <h1>THE SCOPE</h1>
          </div>
          <div className={classes.divInputs}>
            <TextField
              fullWidth
              id="username"
              label="Username"
              variant="outlined"
              type="email"
              onChange={e => {
                setEmail(e.target.value)
              }}
            />
          </div>
          <div className={classes.divInputs}>
            <TextField
              fullWidth
              id="password"
              label="Password"
              type="password"
              variant="outlined"
              onChange={e => {
                setPass(e.target.value)
              }}
            />
          </div>
          {result.user.feedback && (
            <div className={classes.divFeedback}>Wrong email or password</div>
          )}
          <div className={classes.divInputs}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              className={classes.button}
              startIcon={<SendIcon />}
              onClick={() => {
                testLogin(email, pass)
              }}
            >
              Log In
            </Button>
          </div>
        </form>
      </Grid>
    </Container>
  )
}

export default LoginScreen
