import React, { useEffect } from 'react'
import { Container, Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import axios from 'axios'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save'
import qs from 'qs'
import NavBar from '../../components/NavBar'
import { urlApi, config } from '../../utils/configs'

const useStyles = makeStyles(theme => ({
  button: {},
  divInputs: {
    margin: '1.5rem 0'
  },
  divH1: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonPainel: {
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 18
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  },
  divFeedback: {
    display: 'flex',
    justifyContent: 'center',
    color: 'darkgreen',
    fontWeight: 'bold',
    border: '1px solid darkgreen',
    borderRadius: 4,
    padding: 10
  },
  table: {
    minWidth: 650
  }
}))

function ProjectList() {
  const classes = useStyles()
  const history = useHistory()
  const [catsLoad, setCatStateLoad]: any = React.useState([])
  const [sheets, setSheets]: any = React.useState([])
  const [value, setValue]: any = React.useState(1)
  const [showFrom, setFrom]: any = React.useState('LABOR')

  const handlerInput = (query: any, idItem: any, idCat: any) => {
    const update = catsLoad
    update.forEach((c: any) => {
      if (c._id === idCat) {
        c.items.forEach((i: any) => {
          if (i._id === idItem) {
            Object.assign(i, query)
          }
        })
      }
    })

    setCatStateLoad(update)
    const filterReset = update.filter((c: any) => c.cat === value)
    setSheets(filterReset)
  }

  const handlerRemove = (idItem: any, idCat: any) => {
    const update = catsLoad
    update.forEach((c: any) => {
      if (c._id === idCat) {
        c.items.forEach((i: any, key: any) => {
          if (i._id === idItem) {
            const data = qs.stringify({
              idSheet: c._id,
              idSheetItem: i._id
            })

            axios
              .post(`${urlApi}/projects/deleteSheetItem`, data, config)
              .then(function(res) {
                if (res.data.feedback.status === 'success') {
                  return true
                }

                return true
              })
              .catch(function(error) {
                console.log(error)
              })
            delete c.items[key]
          }
        })
      }
    })

    setCatStateLoad(update)
    const filterReset = update.filter((c: any) => c.cat === value)
    setSheets(filterReset)
  }

  const handlerAdd = (idCat: any) => {
    const update = catsLoad
    update.forEach((c: any) => {
      if (c._id === idCat) {
        const d = new Date()
        const n = d.getMilliseconds()
        c.items.push({
          cod: 'INSERT COD',
          config: 'default',
          pdf: 'default',
          name: 'INSERT NAME',
          showIn: 'both',
          _id: n.toString(),
          action: 'add'
        })
      }
    })

    setCatStateLoad(update)
    const filterReset = update.filter((c: any) => c.cat === value)
    setSheets(filterReset)
  }

  const handlerSave = (idItem: any, idCat: any) => {
    const update = catsLoad
    update.forEach((c: any) => {
      if (c._id === idCat) {
        c.items.forEach((i: any) => {
          if (i._id === idItem) {
            const data = qs.stringify({
              idSheet: c._id,
              idSheetItem: i._id,
              showIn: i.showIn,
              cod: i.cod,
              name: i.name,
              config: i.config,
              action: i.action ? i.action : 'update',
              pdf: i.pdf ? i.pdf : 'update'
            })

            axios
              .post(`${urlApi}/projects/saveSheets`, data, config)
              .then(function(res) {
                if (res.data.feedback.status === 'success') {
                  return true
                }

                return true
              })
              .catch(function(error) {
                console.log(error)
              })
          }
        })
      }
    })
  }

  useEffect(() => {
    axios
      .get(`${urlApi}/projects/getSheets`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
      .then(function(res) {
        if (res.data.feedback.status === 'success') {
          setCatStateLoad(res.data.data)
          const filterReset = res.data.data.filter((c: any) => c.cat === value)
          setSheets(filterReset)
        }
      })
      .catch(function(error) {
        console.log(error)
      })
  }, [])


  return (
    <>
      <NavBar />
      <Container fixed>
        <Grid style={{ marginTop: 30 }}>
          <Typography variant="h4" className={classes.title}>
            Edit Sheets
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              fullWidth
              onChange={e => {
                const filterReset = catsLoad.filter(
                  (c: any) => c.cat === e.target.value
                )
                setValue(e.target.value)
                setSheets(filterReset)
              }}
            >
              <MenuItem value={1}>
                Showing items in Category One and Two
              </MenuItem>
              <MenuItem value={3}>Showing items in Category Three</MenuItem>
              <MenuItem value={4}>Showing items in Mold</MenuItem>
            </Select>
          </Typography>
          <Select
            id="showFrom"
            value={showFrom}
            fullWidth
            onChange={e => {
              setFrom(e.target.value)
            }}
          >
            <MenuItem value="LABOR">LABOR</MenuItem>
            <MenuItem value="CONTAINMENT">CONTAINMENT</MenuItem>
            <MenuItem value="EXTRACTION">EXTRACTION</MenuItem>
            <MenuItem value="CARPET">CARPET</MenuItem>
            <MenuItem value="APPLIANCES">APPLIANCES</MenuItem>
            <MenuItem value="MISCELLANEOUS">MISCELLANEOUS</MenuItem>
            <MenuItem value="PERSONAL PROTECTION">PERSONAL PROTECTION</MenuItem>
            <MenuItem value="REMOVAL">REMOVAL</MenuItem>
            <MenuItem value="PLUMBING">PLUMBING</MenuItem>
            <MenuItem value="ELECTRIC">ELECTRIC</MenuItem>
            <MenuItem value="HVAC">HVAC</MenuItem>
            <MenuItem value="DRYING EQUIPMENT">DRYING EQUIPMENT</MenuItem>
            <MenuItem value="DISINFECTANT">DISINFECTANT</MenuItem>
            <MenuItem value="CABINETS">CABINETS</MenuItem>
            <MenuItem value="CLEANING">CLEANING</MenuItem>
          </Select>
          {sheets
            .filter((c: any) => c.name === showFrom)
            .map((c: any) => (
              <div key={c._id}>
                <Typography variant="h6" className={classes.title}>
                  {c.name}
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: 10 }}
                    endIcon={<AddIcon />}
                    onClick={() => {
                      handlerAdd(c._id)
                    }}
                  >
                    ADD
                  </Button>
                </Typography>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>COD</TableCell>
                        <TableCell>NAME</TableCell>
                        <TableCell>SHOW IN</TableCell>
                        <TableCell>FIELD TYPE</TableCell>
                        <TableCell>PDF CALC.</TableCell>
                        <TableCell>OPTIONS</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {c.items.map((i: any) => (
                        <TableRow key={i._id}>
                          <TableCell>
                            <TextField
                              fullWidth
                              id={i._id}
                              variant="outlined"
                              value={i.cod}
                              onChange={e => {
                                if (e.target.value === '') {
                                  return false
                                }
                                const query = {
                                  cod: e.target.value
                                }
                                handlerInput(query, i._id, c._id)
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              fullWidth
                              id={`${i._id}-name`}
                              variant="outlined"
                              value={i.name}
                              onChange={e => {
                                if (e.target.value === '') {
                                  return false
                                }
                                const query = {
                                  name: e.target.value
                                }
                                handlerInput(query, i._id, c._id)
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <Select
                              id={`${i._id}-showIn`}
                              value={i.showIn}
                              fullWidth
                              onChange={e => {
                                if (e.target.value === '') {
                                  return false
                                }
                                const query = {
                                  showIn: e.target.value
                                }
                                handlerInput(query, i._id, c._id)
                              }}
                            >
                              <MenuItem value="regular">Regular Hours</MenuItem>
                              <MenuItem value="after">After Hours</MenuItem>
                              <MenuItem value="both">Both</MenuItem>
                            </Select>
                          </TableCell>
                          <TableCell>
                            <Select
                              id={`${i._id}-config`}
                              value={i.config || 'default'}
                              fullWidth
                              onChange={e => {
                                if (e.target.value === '') {
                                  return false
                                }
                                const query = {
                                  config: e.target.value
                                }
                                handlerInput(query, i._id, c._id)
                              }}
                            >
                              <MenuItem value="default">Default</MenuItem>
                              <MenuItem value="tipoTHR">Type THR</MenuItem>
                              <MenuItem value="tipoAMD">Type AMD</MenuItem>
                              <MenuItem value="tipoMSD">Type MSD</MenuItem>
                              <MenuItem value="tipoDUD">Type DUD</MenuItem>
                              <MenuItem value="tipoDSD">Type DSD</MenuItem>
                              <MenuItem value="tipoINJD">Type INJD</MenuItem>
                              <MenuItem value="tipoFMSD">Type FMSD</MenuItem>
                              <MenuItem value="tipoHYDD">Type HYDD</MenuItem>
                              <MenuItem value="tipoTBD">Type TBD</MenuItem>
                              <MenuItem value="tipoASD">Type ASD</MenuItem>
                              <MenuItem value="tipoPDD">Type PDD</MenuItem>
                              <MenuItem value="tipoLFRD">Type LFRD</MenuItem>
                              <MenuItem value="tipoRD">Type RD</MenuItem>
                              <MenuItem value="tipoYN">Type YN</MenuItem>
                              <MenuItem value="tipoRDR">Type RDR</MenuItem>
                              <MenuItem value="tipoSF">Type SF</MenuItem>
                              <MenuItem value="tipoLF">Type LF</MenuItem>
                              <MenuItem value="tipoYDS">Type YDS</MenuItem>
                              <MenuItem value="tipoHR">Type HR</MenuItem>
                              <MenuItem value="tipoPD">Type PD</MenuItem>
                              <MenuItem value="tipoD">Type D</MenuItem>
                            </Select>
                          </TableCell>
                          <TableCell>
                            <TextField
                              fullWidth
                              id={`${i._id}-pdf`}
                              variant="outlined"
                              value={i.pdf}
                              onChange={e => {
                                if (e.target.value === '') {
                                  return false
                                }
                                const query = {
                                  pdf: e.target.value
                                }
                                handlerInput(query, i._id, c._id)
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                handlerRemove(i._id, c._id)
                              }}
                              aria-label="delete"
                            >
                              <DeleteIcon fontSize="large" />
                            </IconButton>
                            <IconButton
                              style={{ marginLeft: 10 }}
                              onClick={() => {
                                handlerSave(i._id, c._id)
                              }}
                              aria-label="delete"
                            >
                              <SaveIcon fontSize="large" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ))}
        </Grid>
      </Container>
    </>
  )
}

export default ProjectList
