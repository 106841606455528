import React from 'react'
import 'date-fns'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import SaveIcon from '@material-ui/icons/Save'
import { Container, TextField, Grid, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers'
import NavBar from '../../components/NavBar'
import { handlerStartNewProject } from '../../store/scope/action'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    },
    nested: {
      paddingLeft: theme.spacing(3),
      backgroundColor: '#f0f0f0'
    },
    divInputs: {
      margin: '1rem 0',
      width: '100%'
    },
    divInputsFinal: {
      margin: '2rem 0',
      width: '100%'
    },
    formControl: {
      margin: '0.2rem 0',
      backgroundColor: '#f0f0f0'
    },
    buttonSave: {
      position: 'fixed',
      zIndex: 10,
      borderRadius: 0,
      bottom: 0,
      padding: 20
    },
    gridPadding: {
      paddingBottom: 70
    },
    listItem: {
      fontSize: '0.9rem'
    }
  })
)

export default function CreateProject() {
  const [date, setDate] = React.useState<Date | null>(new Date())
  const [time, setTime] = React.useState<Date | null>(new Date())

  const handleDateChange = (date: Date | null) => {
    setDate(date)
  }

  const handleTimeChange = (date: Date | null) => {
    setTime(date)
  }

  interface OpenProps {
    [x: number]: any
  }
  interface ItemsProps {
    [x: string]: any
  }
  const classes = useStyles()
  const [costumeName, setCostumeName] = React.useState('')
  const [leadTech, setLeadTech] = React.useState('')

  const history = useHistory()
  const dispatch = useDispatch()
  const result: any = useSelector(state => state)
  const { user } = result

  const saveProject = () => {
    dispatch(
      handlerStartNewProject({
        id: costumeName,
        costumer: costumeName,
        leadTech,
        date,
        time,
        history
      })
    )
  }

  return (
    <>
      <NavBar />
      <Container fixed>
        <h2>New Project</h2>
        <div className={classes.divInputs}>
          <TextField
            fullWidth
            id="costumer"
            label="Costumer Name"
            variant="outlined"
            onChange={e => {
              setCostumeName(e.target.value)
            }}
          />
        </div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container>
            <div className={classes.divInputs}>
              <KeyboardDatePicker
                margin="normal"
                fullWidth
                id="date-picker-dialog"
                label="Date picker"
                format="MM/dd/yyyy"
                value={date}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </div>
            <div className={classes.divInputs}>
              <KeyboardTimePicker
                fullWidth
                margin="normal"
                id="time-picker"
                label="Time picker"
                value={time}
                onChange={handleTimeChange}
                KeyboardButtonProps={{
                  'aria-label': 'change time'
                }}
              />
            </div>
          </Grid>
        </MuiPickersUtilsProvider>
        <div className={classes.divInputs}>
          <TextField
            fullWidth
            id="lead_tech"
            label="Lead Tech"
            variant="outlined"
            onChange={e => {
              setLeadTech(e.target.value)
            }}
          />
        </div>
        <div className={classes.divInputsFinal}>
          <TextField
            fullWidth
            id="crew"
            label="Project Manager"
            variant="outlined"
            disabled
            value={user.name}
          />
        </div>
      </Container>
      <Grid className={classes.gridPadding}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          className={classes.buttonSave}
          startIcon={<SaveIcon />}
          onClick={() => {
            saveProject()
          }}
        >
          CREATE PROJECT
        </Button>
      </Grid>
    </>
  )
}
