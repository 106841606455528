import {
  ADD_PROJECT,
  CREATE_ROOM,
  GET_PROJECTS,
  UPDATE_ROOM,
  DELETE_PROJECT,
  DELETE_ROOM,
  CLEAN_LOGOUT,
  EDIT_PROJECT
} from './types'

const initialState: any = {
  projects: { data: [] }
}

export function scopeReducer(state = initialState, action: any): any {
  switch (action.type) {
    case CLEAN_LOGOUT:
      return {
        projects: { data: [] }
      }
    case GET_PROJECTS:
      return {
        projects: action.payload
      }
    case ADD_PROJECT:
      return {
        projects: { data: [...state.projects.data, action.payload.data] }
      }
    case EDIT_PROJECT:
      state.projects.data.forEach((p: any) => {
        if (p._id === action.payload.data._id) {
          Object.assign(p, action.payload.data)
        }
      })

      return {
        ...state
      }
    case CREATE_ROOM:
      state.projects.data.forEach((s: any) => {
        if (s._id === action.meta._id) {
          s.rooms = action.meta.rooms
        }
      })

      return {
        ...state
      }
    case DELETE_PROJECT:
      const finalProjects: any = []
      state.projects.data.forEach((s: any) => {
        if (s._id !== action.meta._id) {
          finalProjects.push(s)
        }
      })
      state.projects.data = finalProjects

      return {
        ...state
      }
    case DELETE_ROOM:
      state.projects.data.forEach((s: any) => {
        if (s._id === action.meta._id) {
          s.rooms = action.meta.rooms
        }
      })

      return {
        ...state
      }
    case UPDATE_ROOM:
      state.projects.data.forEach((s: any) => {
        if (s._id === action.meta._id) {
          s.rooms = action.meta.rooms
        }
      })

      return {
        ...state
      }
    default:
      return { ...state }
  }
}
