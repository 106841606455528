import React, { useEffect } from 'react'
import { Container, Grid, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import { useHistory, useLocation } from 'react-router-dom'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import axios from 'axios'
import NavBar from '../../components/NavBar'
import { config, urlApi } from '../../utils/configs'

const useStyles = makeStyles(theme => ({
  button: {},
  divInputs: {
    margin: '1.5rem 0'
  },
  divH1: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonPainel: {
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 18
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  },
  divFeedback: {
    display: 'flex',
    justifyContent: 'center',
    color: 'darkgreen',
    fontWeight: 'bold',
    border: '1px solid darkgreen',
    borderRadius: 4,
    padding: 10
  }
}))

function ListUser() {
  const classes = useStyles()
  const history = useHistory()
  const [open, setOpen] = React.useState(false)
  const [idUser, setIdUser] = React.useState('')
  const [userDeleted, setUserDeleted] = React.useState(false)

  const [listUsers, setListUsers] = React.useState([])

  const location: any = useLocation()
  let userSuccess = false
  if (location.state) {
    userSuccess = true
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const confirmDelete = () => {
    setUserDeleted(false)
    axios
      .delete(`${urlApi}/users/deleteUser/${idUser}`, config)
      .then(function(res) {
        if (res.data.feedback.status === 'success') {
          handleClose()
          setUserDeleted(true)
          setListUsers(prev => {
            return prev.filter((user: { _id: string }) => user._id !== idUser)
          })
        }
      })
  }

  useEffect(() => {
    axios
      .get(`${urlApi}/users/getAllUsers`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
      .then(function(res) {
        if (res.data.feedback.status === 'success') {
          setListUsers(res.data.data)
        }
      })
  }, [])

  return (
    <>
      <NavBar />
      <Container fixed>
        <Grid style={{ marginTop: 30 }}>
          <div className={classes.divInputs}>
            <Button
              className={classes.buttonPainel}
              size="large"
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => {
                history.push('/painel/create-user')
              }}
            >
              CREATE A NEW USER
            </Button>
          </div>
          {userSuccess && !userDeleted && (
            <div className={classes.divFeedback}>User created successfully</div>
          )}
          {userDeleted && (
            <div className={classes.divFeedback}>User deleted successfully</div>
          )}
          <div className={classes.divInputs}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" className={classes.title}>
                List of Users
              </Typography>
              <div className={classes.demo}>
                <List>
                  {listUsers.map((a: any) => (
                    <ListItem key={a._id}>
                      <ListItemAvatar>
                        <Avatar>
                          <AccountCircleIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={a.name} />
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() => {
                            setIdUser(a._id)
                            handleClickOpen()
                          }}
                          edge="end"
                          aria-label="Delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Grid>
          </div>
        </Grid>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Do you really want to delete this user?
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Disagree
            </Button>
            <Button onClick={confirmDelete} color="primary" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  )
}

export default ListUser
