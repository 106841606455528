import React from 'react'
import { TextField } from '@material-ui/core'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

// Um campos para numero, T string, campos numero , HR string / PDF: Multiplicas T x HR
export const checkField = (
  config: string,
  c: any,
  i: any,
  callback: Function,
  dinamicValue: any
) => {
  if (config === 'tipoTHR') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>T</div>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>HR</div>
      </div>
    )
  }

  if (config === 'tipoAMD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>AM</div>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  if (config === 'tipoMSD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>MS</div>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  if (config === 'tipoDUD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>DU</div>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  if (config === 'tipoDSD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>DS</div>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  if (config === 'tipoINJD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>INJ</div>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  if (config === 'tipoFMSD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>FMS</div>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  if (config === 'tipoHYDD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>HYD</div>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  if (config === 'tipoTBD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>TB</div>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  if (config === 'tipoASD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>AS</div>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  if (config === 'tipoPDD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>PD</div>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  if (config === 'tipoLFRD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <div>
          <TextField
            size="small"
            id={`${c.name}-${i.cod}-1`}
            onChange={e => {
              callback(e, c.name, `${i.cod}-1`)
            }}
            fullWidth
            label="Answer"
            variant="outlined"
          />
        </div>
        <div style={{ padding: '0 15px 0 5px' }}>LF</div>
        <RadioGroup
          aria-label="category"
          name={`${c.name}-${i.cod}-2`}
          onClick={e => {
            callback(e, c.name, `${i.cod}-2`, 'radio')
          }}
          style={{ flexDirection: 'row' }}
          value={dinamicValue[`${c.name}_${i.cod}-2`] || ''}
        >
          <FormControlLabel value="R" control={<Radio />} label="R" />
          <FormControlLabel value="D" control={<Radio />} label="D" />
        </RadioGroup>
      </div>
    )
  }

  if (config === 'tipoRD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <div>
          <TextField
            size="small"
            id={`${c.name}-${i.cod}-1`}
            onChange={e => {
              callback(e, c.name, `${i.cod}-1`)
            }}
            fullWidth
            label="Answer"
            variant="outlined"
          />
        </div>
        <RadioGroup
          aria-label="category"
          name={`${c.name}-${i.cod}-2`}
          onClick={e => {
            callback(e, c.name, `${i.cod}-2`, 'radio')
          }}
          style={{ flexDirection: 'row' }}
          value={dinamicValue[`${c.name}_${i.cod}-2`] || ''}
        >
          <FormControlLabel value="R" control={<Radio />} label="R" />
          <FormControlLabel value="D" control={<Radio />} label="D" />
        </RadioGroup>
      </div>
    )
  }

  if (config === 'tipoYN') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <RadioGroup
          aria-label="category"
          name={`${c.name}-${i.cod}`}
          onClick={e => {
            callback(e, c.name, `${i.cod}`, 'radio')
          }}
          style={{ flexDirection: 'row' }}
          value={dinamicValue[`${c.name}_${i.cod}`] || ''}
        >
          <FormControlLabel value="Y" control={<Radio />} label="Y" />
          <FormControlLabel value="N" control={<Radio />} label="N" />
        </RadioGroup>
      </div>
    )
  }

  if (config === 'tipoRDR') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <div style={{ paddingRight: 5 }}>
          <TextField
            size="small"
            id={`${c.name}-${i.cod}-1`}
            onChange={e => {
              callback(e, c.name, `${i.cod}-1`)
            }}
            fullWidth
            label="Answer"
            variant="outlined"
          />
        </div>
        <RadioGroup
          aria-label="category"
          name={`${c.name}-${i.cod}-2`}
          onClick={e => {
            callback(e, c.name, `${i.cod}-2`, 'radio')
          }}
          style={{ flexDirection: 'row' }}
          value={dinamicValue[`${c.name}_${i.cod}-2`] || ''}
        >
          <FormControlLabel value="R" control={<Radio />} label="R" />
          <FormControlLabel value="DR" control={<Radio />} label="DR" />
        </RadioGroup>
      </div>
    )
  }

  if (config === 'tipoSF') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}`}
          onChange={e => {
            callback(e, c.name, `${i.cod}`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>SF</div>
      </div>
    )
  }

  if (config === 'tipoLF') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}`}
          onChange={e => {
            callback(e, c.name, `${i.cod}`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>LF</div>
      </div>
    )
  }

  if (config === 'tipoYDS') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}`}
          onChange={e => {
            callback(e, c.name, `${i.cod}`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>YDS</div>
      </div>
    )
  }

  if (config === 'tipoHR') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}`}
          onChange={e => {
            callback(e, c.name, `${i.cod}`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>HR</div>
      </div>
    )
  }

  if (config === 'tipoPD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>P</div>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  if (config === 'tipoD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}`}
          onChange={e => {
            callback(e, c.name, `${i.cod}`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  return (
    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <TextField
        size="small"
        id={`${c.name}-${i.cod}`}
        onChange={e => {
          callback(e, c.name, `${i.cod}`)
        }}
        fullWidth
        label="Answer"
        variant="outlined"
      />
    </div>
  )
}

export const checkFieldEdit = (
  config: string,
  c: any,
  i: any,
  callback: Function
) => {
  let values = []
  if (i.text) {
    values = i.text.split('#')
  }
  if (config === 'tipoTHR') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          fullWidth
          value={values[0] || ''}
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>T</div>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          fullWidth
          label="Answer"
          value={values[1] || ''}
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>HR</div>
      </div>
    )
  }
  if (config === 'tipoAMD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          value={values[0] || ''}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>AM</div>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          value={values[1] || ''}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  if (config === 'tipoMSD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          value={values[0] || ''}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>MS</div>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          value={values[1] || ''}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  if (config === 'tipoDUD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          value={values[0] || ''}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>DU</div>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-2`}
          value={values[1] || ''}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  if (config === 'tipoDSD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          value={values[0] || ''}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>DS</div>
        <TextField
          size="small"
          value={values[1] || ''}
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  if (config === 'tipoINJD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          value={values[0] || ''}
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>INJ</div>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          value={values[1] || ''}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  if (config === 'tipoFMSD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          fullWidth
          value={values[0] || ''}
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>FMS</div>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          fullWidth
          value={values[1] || ''}
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  if (config === 'tipoHYDD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          value={values[0] || ''}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>HYD</div>
        <TextField
          size="small"
          value={values[1] || ''}
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  if (config === 'tipoTBD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          value={values[0] || ''}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>TB</div>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          value={values[1] || ''}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  if (config === 'tipoASD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          value={values[0] || ''}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>AS</div>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          value={values[1] || ''}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  if (config === 'tipoPDD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          value={values[0] || ''}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>PD</div>
        <TextField
          value={values[1] || ''}
          size="small"
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  if (config === 'tipoLFRD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <div>
          <TextField
            size="small"
            id={`${c.name}-${i.cod}-1`}
            onChange={e => {
              callback(e, c.name, `${i.cod}-1`)
            }}
            value={values[0] || ''}
            fullWidth
            label="Answer"
            variant="outlined"
          />
        </div>
        <div style={{ padding: '0 15px 0 5px' }}>LF</div>
        <RadioGroup
          aria-label="category"
          name={`${c.name}-${i.cod}-2`}
          onClick={e => {
            callback(e, c.name, `${i.cod}-2`, 'radio')
          }}
          value={values[1] || ''}
          style={{ flexDirection: 'row' }}
        >
          <FormControlLabel value="R" control={<Radio />} label="R" />
          <FormControlLabel value="D" control={<Radio />} label="D" />
        </RadioGroup>
      </div>
    )
  }

  if (config === 'tipoRD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <div>
          <TextField
            size="small"
            id={`${c.name}-${i.cod}-1`}
            onChange={e => {
              callback(e, c.name, `${i.cod}-1`)
            }}
            value={values[0] || ''}
            fullWidth
            label="Answer"
            variant="outlined"
          />
        </div>
        <RadioGroup
          aria-label="category"
          name={`${c.name}-${i.cod}-2`}
          onClick={e => {
            callback(e, c.name, `${i.cod}-2`, 'radio')
          }}
          value={values[1] || ''}
          style={{ flexDirection: 'row' }}
        >
          <FormControlLabel value="R" control={<Radio />} label="R" />
          <FormControlLabel value="D" control={<Radio />} label="D" />
        </RadioGroup>
      </div>
    )
  }

  if (config === 'tipoYN') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <RadioGroup
          aria-label="category"
          name={`${c.name}-${i.cod}`}
          onClick={e => {
            callback(e, c.name, `${i.cod}`, 'radio')
          }}
          style={{ flexDirection: 'row' }}
          value={i.text || ''}
        >
          <FormControlLabel value="Y" control={<Radio />} label="Y" />
          <FormControlLabel value="N" control={<Radio />} label="N" />
        </RadioGroup>
      </div>
    )
  }

  if (config === 'tipoRDR') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <div style={{ paddingRight: 5 }}>
          <TextField
            size="small"
            id={`${c.name}-${i.cod}-1`}
            onChange={e => {
              callback(e, c.name, `${i.cod}-1`)
            }}
            value={values[0] || ''}
            fullWidth
            label="Answer"
            variant="outlined"
          />
        </div>
        <RadioGroup
          aria-label="category"
          name={`${c.name}-${i.cod}-2`}
          onClick={e => {
            callback(e, c.name, `${i.cod}-2`, 'radio')
          }}
          value={values[1] || ''}
          style={{ flexDirection: 'row' }}
        >
          <FormControlLabel value="R" control={<Radio />} label="R" />
          <FormControlLabel value="DR" control={<Radio />} label="DR" />
        </RadioGroup>
      </div>
    )
  }

  if (config === 'tipoSF') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}`}
          onChange={e => {
            callback(e, c.name, `${i.cod}`)
          }}
          value={i.text || ''}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>SF</div>
      </div>
    )
  }

  if (config === 'tipoLF') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}`}
          onChange={e => {
            callback(e, c.name, `${i.cod}`)
          }}
          fullWidth
          label="Answer"
          variant="outlined"
          value={i.text || ''}
        />
        <div style={{ padding: '0 5px' }}>LF</div>
      </div>
    )
  }

  if (config === 'tipoYDS') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}`}
          onChange={e => {
            callback(e, c.name, `${i.cod}`)
          }}
          value={i.text || ''}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>YDS</div>
      </div>
    )
  }

  if (config === 'tipoHR') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}`}
          onChange={e => {
            callback(e, c.name, `${i.cod}`)
          }}
          value={i.text || ''}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>HR</div>
      </div>
    )
  }

  if (config === 'tipoPD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-1`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-1`)
          }}
          fullWidth
          value={values[0] || ''}
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>P</div>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}-2`}
          onChange={e => {
            callback(e, c.name, `${i.cod}-2`)
          }}
          value={values[1] || ''}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  if (config === 'tipoD') {
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <TextField
          size="small"
          id={`${c.name}-${i.cod}`}
          onChange={e => {
            callback(e, c.name, `${i.cod}`)
          }}
          value={i.text || ''}
          fullWidth
          label="Answer"
          variant="outlined"
        />
        <div style={{ padding: '0 5px' }}>D</div>
      </div>
    )
  }

  return (
    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <TextField
        size="small"
        id={`${c.name}-${i.cod}`}
        onChange={e => {
          callback(e, c.name, `${i.cod}`)
        }}
        value={i.text || ''}
        fullWidth
        label="Answer"
        variant="outlined"
      />
    </div>
  )
}
