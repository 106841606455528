import React, { useEffect } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import SaveIcon from '@material-ui/icons/Save'
import CloseIcon from '@material-ui/icons/HighlightOff'
import { TextField, Button, IconButton } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import { handlerCreateRoom } from '../../store/scope/action'
import { urlApi } from '../../utils/configs'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    },
    nested: {
      paddingLeft: theme.spacing(3),
      backgroundColor: '#f0f0f0'
    },
    divInputs: {
      margin: '1rem 0'
    },
    formControl: {
      margin: '0.2rem 0',
      backgroundColor: '#f0f0f0'
    },
    buttonSave: {
      zIndex: 10,
      borderRadius: 0,
      padding: 20
    },
    gridPadding: {
      paddingBottom: 90
    },
    listItem: {
      fontSize: '0.9rem'
    },
    paper: {
      width: '80%',
      margin: 'auto',
      marginTop: '30%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2)
    },
    divClose: {
      position: 'absolute',
      top: '-28px',
      right: '-28px',
      backgroundColor: '#fff'
    }
  })
)

export default function CreateRoomModal(props: any) {
  const dispatch = useDispatch()
  const { id } = useParams()
  const history = useHistory()

  interface ItemsProps {
    [x: string]: any
  }
  const classes = useStyles()
  const [value, setValue] = React.useState(1)
  const [valueFilter, setValueFilter] = React.useState(1)
  const [classRoom, setClassRoom] = React.useState('1')
  const [hours, setHours] = React.useState('regular')
  const [roomName, setRoomName] = React.useState('')
  const [roomItems, setItems] = React.useState<ItemsProps>({})
  const [cats, setCats]: any = React.useState([])

  useEffect(() => {
    axios
      .get(`${urlApi}/projects/getSheets`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
      .then(function(res) {
        if (res.data.feedback.status === 'success') {
          setCats(res.data.data)
        }
      })
      .catch(function(error) {
        console.log(error)
      })
  }, [])

  const saveProject = () => {
    interface FinalRoomArray {
      cod: string
      name: string
      text: any
      config: string
      pdf: string
    }
    interface FinalRoom {
      name: string
      items: FinalRoomArray[]
    }
    const finalRoom: any = []
    const catsFilter = cats.filter((i: any) => i.cat === valueFilter)
    catsFilter.forEach((c: any) => {
      const catName: FinalRoom = { name: c.name, items: [] }
      c.items.forEach((i: any) => {
        const codeMerge = `${c.name}_${i.cod}`
        if (Object.keys(roomItems).indexOf(codeMerge) > -1) {
          catName.items.push({
            cod: i.cod,
            name: i.name,
            text: roomItems[codeMerge],
            config: i.config,
            pdf: i.pdf
          })
        }
        const codeMerge1 = `${c.name}_${i.cod}-1`
        const codeMerge2 = `${c.name}_${i.cod}-2`
        if (Object.keys(roomItems).indexOf(codeMerge1) > -1) {
          catName.items.push({
            cod: i.cod,
            name: i.name,
            text: `${roomItems[codeMerge1]}#${roomItems[codeMerge2]}`,
            config: i.config,
            pdf: i.pdf
          })
        }
      })
      finalRoom.push(catName)
    })

    if (id) {
      dispatch(
        handlerCreateRoom({
          idProject: id,
          name: roomName,
          notes: '',
          category: value,
          items: finalRoom,
          hours,
          classRoom,
          cb: history
        })
      )
    }
  }

  return (
    <div>
      <div className={classes.divInputs}>
        <TextField
          fullWidth
          id="outlined-basic"
          label="Room Name"
          variant="outlined"
          onChange={e => {
            setRoomName(e.target.value)
          }}
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        className={classes.buttonSave}
        startIcon={<SaveIcon />}
        onClick={() => {
          saveProject()
        }}
      >
        SAVE ROOM
      </Button>
      <IconButton
        onClick={() => {
          props.handleCloseModal()
        }}
        color="secondary"
        aria-label="Close Modal"
        className={classes.divClose}
      >
        <CloseIcon fontSize="large" />
      </IconButton>
    </div>
  )
}
