import {
  TRY_LOGIN,
  UserActionTypes,
  UserInfoInterface,
  GET_USER_INFO
} from './types'

const initialState: any = {}

export function userReducer(state = initialState, action: any): any {
  switch (action.type) {
    case TRY_LOGIN:
      return {
        ...state,
        ...action.user
      }
    case GET_USER_INFO:
      return {
        ...state,
        ...action.user
      }
    default:
      return state
  }
}
