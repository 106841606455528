import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import qs from 'qs'
import axios from 'axios'
import { TRY_LOGIN, GET_USER_INFO, LOGOUT } from './types'
import { AppState } from '../index'
import { urlApi, config } from '../../utils/configs'

function userTryLogin(newStepBar: any) {
  return {
    type: TRY_LOGIN,
    user: newStepBar
  }
}

export const handlerUserTryLogin = (
  user: any
): ThunkAction<void, AppState, unknown, Action<string>> => async dispatch => {
  const data = qs.stringify({
    email: user.email,
    password: user.pass
  })

  axios
    .post(`${urlApi}/users/authenticate`, data, config)
    .then(function(res) {
      if (res.data.feedback.status === 'success') {
        dispatch(userTryLogin(res.data.data))
        user.history.push('/painel')
      } else {
        dispatch(userTryLogin(res.data))
      }
    })
    .catch(function(error) {
      console.log(error)
    })
}

function getUserInfo(user: any) {
  return {
    type: GET_USER_INFO,
    user
  }
}

export const handlerGetUserInfo = (
  user: any
): ThunkAction<void, AppState, unknown, Action<string>> => async dispatch => {
  axios
    .get(`${urlApi}/users/getUserInfo`, {
      withCredentials: true,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .then(function(res) {
      if (res.data.feedback.status === 'success') {
        return dispatch(getUserInfo(res.data.data))
      }
      user.history.push('/')
      document.cookie =
        '_tkpresence=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    })
    .catch(function(error) {
      console.log(error)
    })
}
function userLogout() {
  return {
    type: LOGOUT
  }
}

export const handlerUserLogout = (): ThunkAction<
  void,
  AppState,
  unknown,
  Action<string>
> => async dispatch => {
  axios
    .post(`${urlApi}/users/logout`, {}, config)
    .then(function(res) {
      document.cookie =
        '_tkpresence=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'

      return dispatch(userLogout())
    })
    .catch(function(error) {
      console.log(error)
    })
}
