import React, { useEffect, useRef } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import CreateIcon from '@material-ui/icons/Create'
import SaveIcon from '@material-ui/icons/Save'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Container, TextField, Grid, Button } from '@material-ui/core'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Icon from '@material-ui/core/Icon'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import axios from 'axios'
import NoteIcon from '@material-ui/icons/Note'
import Modal from '@material-ui/core/Modal'
import NavBar from '../../components/NavBar'
import { checkFieldEdit } from '../../utils/generateFields'
import { handlerUpdateRoom, handlerDeleteRoom } from '../../store/scope/action'
import { urlApi } from '../../utils/configs'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    },
    nested: {
      paddingLeft: theme.spacing(3),
      backgroundColor: '#f0f0f0'
    },
    divInputs: {
      margin: '1rem 0'
    },
    formControl: {
      margin: '0.2rem 0',
      backgroundColor: '#f0f0f0'
    },
    buttonSave: {
      position: 'fixed',
      zIndex: 10,
      borderRadius: 0,
      bottom: 0,
      padding: 20,
      width: '60%',
      right: 0
    },
    buttonAddNote: {
      position: 'fixed',
      zIndex: 10,
      borderRadius: 0,
      bottom: 0,
      padding: 20,
      width: '40%',
      left: 0,
      backgroundColor: '#002884'
    },
    gridPadding: {
      paddingBottom: 90
    },
    listItem: {
      fontSize: '0.9rem'
    },
    paper: {
      width: '80%',
      margin: 'auto',
      marginTop: '30%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2)
    }
  })
)

export default function CreateRoom() {
  const dispatch = useDispatch()
  const { id, idRoom } = useParams()
  const { state }: any = useLocation()
  const history = useHistory()
  const result: any = useSelector((state: any) => state)
  const timeOut = useRef(0)

  interface OpenProps {
    [x: number]: any
  }
  interface ItemsProps {
    [x: string]: any
  }
  const classes = useStyles()

  const [room, setRoomInitial]: any = React.useState({
    class_room: '',
    category: 1,
    hours_type: 'regular',
    name_room: 'Teste',
    note_room: '',
    items: []
  })
  const [open, setOpen] = React.useState<OpenProps>({})
  const [value, setValue] = React.useState(parseInt(room.category))
  const [valueFilter, setValueFilter] = React.useState(parseInt(room.category))
  const [classRoom, setClassRoom] = React.useState(room.class_room.toString())
  const [hours, setHours] = React.useState(room.hours_type)
  const [roomName, setRoomName] = React.useState(room.name_room)
  const [roomItems, setItems] = React.useState<ItemsProps>({})
  const [roomNote, setRoomNote] = React.useState(room.note_room)
  const [openModal, setOpenModal] = React.useState(false)
  const [cats, setCatState]: any = React.useState([])
  const [catsLoad, setCatStateLoad]: any = React.useState([])

  const [openModalNote, setOpenModalNote] = React.useState(false)

  const handleClickOpen = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleOpenModalNote = () => {
    setOpenModalNote(true)
  }

  const handleCloseModalNote = () => {
    setOpenModalNote(false)
  }

  const confirmDelete = () => {
    dispatch(
      handlerDeleteRoom({
        idProject: id,
        idRoom
      })
    )
    handleClose()
    history.goBack()
  }

  const updateCats = (reset = false, value = 1) => {
    if (reset) {
      const filterReset = catsLoad.filter((c: any) => c.cat === value)
      setCatState(filterReset)
    }

    setCatState((prev: any) => {
      prev.forEach((c: any) => {
        room.items.forEach((i: any) => {
          if (i.name === c.name) {
            c.items.forEach((cc: any) => {
              if (i.items) {
                i.items.forEach((ii: any) => {
                  if (cc.cod === ii.cod) {
                    Object.assign(cc, { text: ii.text })
                  }
                })
              }
            })
          }
        })
      })

      return prev
    })
  }

  useEffect(() => {
    axios
      .get(`${urlApi}/projects/getSheets`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
      .then(function(res) {
        if (res.data.feedback.status === 'success') {
          let filterNumber = room.category
          if (filterNumber === 1 || filterNumber === 2) {
            filterNumber = 1
          }
          const filterCat = res.data.data.filter(
            (c: any) => c.cat === filterNumber
          )

          setCatState(filterCat)
          setCatStateLoad(res.data.data)
        }
      })
      .then(() => {
        updateCats()
      })
      .catch(function(error) {
        console.log(error)
      })
  }, [room])

  useEffect(() => {
    axios
      .get(`${urlApi}/projects/getProject`, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        params: { id, idRoom }
      })
      .then(function(res) {
        if (res.data.feedback.status === 'success') {
          res.data.data.rooms.forEach((roomFor: any) => {
            if (roomFor._id === idRoom) {
              setRoomInitial(roomFor)
              setValueFilter(roomFor.category)
              setValue(parseInt(roomFor.category))
              setClassRoom(roomFor.class_room.toString())
              setHours(roomFor.hours_type)
              setRoomName(roomFor.name_room)
              setRoomNote(roomFor.note_room)
            }
          })
        }
      })
  }, [])

  if (!room) {
    history.push('/painel')
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const intValue = parseInt((event.target as HTMLInputElement).value)
    let intValueFilter = parseInt((event.target as HTMLInputElement).value)
    if (intValueFilter === 2) {
      intValueFilter = 1
    }
    setValueFilter(intValueFilter)
    setValue(intValue)
    updateCats(true, intValueFilter)
  }

  const handleChangeClass = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClassRoom((event.target as HTMLInputElement).value)
  }

  const handleClick = (key: number) => {
    setOpen({
      ...open,
      [key]: !open[key]
    })
  }

  const handleChangeHours = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHours((event.target as HTMLInputElement).value)
  }

  const saveProject = (goBack = false) => {
    interface FinalRoomArray {
      cod: string
      name: string
      text: any
      pdf: string
    }
    interface FinalRoom {
      name: string
      items: FinalRoomArray[]
    }
    const finalRoom: any = []
    cats.forEach((c: any) => {
      const catName: FinalRoom = { name: c.name, items: [] }
      c.items.forEach((i: any) => {
        if (i.text) {
          catName.items.push({
            cod: i.cod,
            name: i.name,
            text: i.text,
            pdf: i.pdf
          })
        }
      })
      finalRoom.push(catName)
    })
    if (id && idRoom) {
      dispatch(
        handlerUpdateRoom({
          idProject: id,
          idRoom,
          name: roomName,
          category: value,
          items: finalRoom,
          hours,
          notes: roomNote,
          classRoom
        })
      )
      if (goBack) {
        history.goBack()
      }
    }
  }

  const handlerInput = (e: any, id: any, code: any, type: any) => {
    clearTimeout(timeOut.current)
    timeOut.current = setTimeout(() => {
      saveProject()
    }, 3000)
    const codeMerge = `${id}_${code}`
    let valueCheck = e.target.value
    if (type === 'radio') {
      if (valueCheck === roomItems[codeMerge]) {
        valueCheck = ''
      }
    }
    setItems({
      ...roomItems,
      [codeMerge]: valueCheck
    })
    cats.forEach((l: any) => {
      if (l.name === id) {
        l.items.forEach((ll: any) => {
          if (ll.cod === code) {
            Object.assign(ll, { text: valueCheck })
          }
          if (`${ll.cod}-1` === code) {
            let values = []
            if (ll.text) {
              values = ll.text.split('#')
            }
            const finalText = `${valueCheck}#${values[1] ? values[1] : ''}`
            Object.assign(ll, { text: finalText })
          }
          if (`${ll.cod}-2` === code) {
            let values = []
            if (ll.text) {
              values = ll.text.split('#')
            }
            const finalText = `${values[0] ? values[0] : ''}#${valueCheck}`
            Object.assign(ll, { text: finalText })
          }
        })
      }
    })
  }

  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Do you really want to delete this project?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Disagree
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <NavBar />
      <Container fixed>
        <div
          style={{
            margin: '20px 0',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Button
            onClick={() => {
              history.goBack()
            }}
            color="primary"
            startIcon={<Icon>arrow_back</Icon>}
          >
            Go Back
          </Button>
          <Button
            onClick={() => {
              handleClickOpen()
            }}
            color="secondary"
            endIcon={<Icon>delete</Icon>}
          >
            Delete Room
          </Button>
        </div>
        <div className={classes.divInputs}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Room Name"
            variant="outlined"
            value={roomName}
            onChange={e => {
              setRoomName(e.target.value)
            }}
          />
        </div>
        <div className={classes.divInputs}>
          <FormControl fullWidth component="fieldset">
            <FormLabel component="legend">Category</FormLabel>
            <RadioGroup
              aria-label="category"
              name="category1"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel
                className={classes.formControl}
                value={1}
                control={<Radio />}
                label="1"
              />
              <FormControlLabel
                className={classes.formControl}
                value={2}
                control={<Radio />}
                label="2"
              />
              <FormControlLabel
                className={classes.formControl}
                value={3}
                control={<Radio />}
                label="3"
              />
              <FormControlLabel
                className={classes.formControl}
                value={4}
                control={<Radio />}
                label="Mold"
              />
            </RadioGroup>
          </FormControl>
          <div style={{ marginTop: 15 }}>
            <FormControl fullWidth component="fieldset">
              <FormLabel component="legend">Class</FormLabel>
              <RadioGroup
                aria-label="class"
                name="class"
                value={classRoom}
                onChange={handleChangeClass}
              >
                <FormControlLabel
                  className={classes.formControl}
                  value="1"
                  control={<Radio />}
                  label="1"
                />
                <FormControlLabel
                  className={classes.formControl}
                  value="2"
                  control={<Radio />}
                  label="2"
                />
                <FormControlLabel
                  className={classes.formControl}
                  value="3"
                  control={<Radio />}
                  label="3"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div style={{ marginTop: 15 }}>
            <FormControl fullWidth component="fieldset">
              <FormLabel component="legend">Hours</FormLabel>
              <RadioGroup
                aria-label="class"
                name="class"
                value={hours}
                onChange={handleChangeHours}
              >
                <FormControlLabel
                  className={classes.formControl}
                  value="regular"
                  control={<Radio />}
                  label="Regular Hours"
                />
                <FormControlLabel
                  className={classes.formControl}
                  value="after"
                  control={<Radio />}
                  label="After Hours"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <Grid className={classes.gridPadding}>
          {cats.map((c: any) => (
            <div key={c.name}>
              <ListItem
                button
                onClick={() => {
                  handleClick(c._id)
                }}
              >
                <ListItemIcon>
                  <CreateIcon />
                </ListItemIcon>
                <ListItemText primary={c.name} />
                {open[c._id] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={!!open[c._id]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {c.items
                    .filter(
                      (i: any) => i.showIn === 'both' || i.showIn === hours
                    )
                    .map((i: any) => (
                      <ListItem key={i.cod} className={classes.nested}>
                        <div style={{ display: 'flex', width: '100%' }}>
                          <div style={{ width: '50%', paddingRight: 10 }}>
                            <ListItemText
                              classes={{
                                primary: classes.listItem
                              }}
                              primary={i.name}
                            />
                          </div>
                          {checkFieldEdit(i.config, c, i, handlerInput)}
                        </div>
                      </ListItem>
                    ))}
                </List>
              </Collapse>
            </div>
          ))}
        </Grid>
      </Container>
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        className={classes.buttonSave}
        startIcon={<SaveIcon />}
        onClick={() => {
          saveProject(true)
        }}
      >
        SAVE ROOM
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        className={classes.buttonAddNote}
        startIcon={<NoteIcon />}
        onClick={handleOpenModalNote}
      >
        ADD NOTE
      </Button>
      <Modal
        open={openModalNote}
        onClose={handleCloseModalNote}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <TextField
            id="outlined-multiline-static"
            fullWidth
            label="Add Notes"
            multiline
            rows={15}
            variant="outlined"
            onChange={e => {
              setRoomNote(e.target.value)
            }}
            value={roomNote}
          />
        </div>
      </Modal>
    </>
  )
}
