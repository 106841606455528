import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import { scopeReducer } from './scope/reducers'
import { userReducer } from './user/reducers'

const rootReducer = combineReducers({
  scope: scopeReducer,
  user: userReducer
})

export type AppState = ReturnType<typeof rootReducer>

export default function configureStore() {
  const middlewares = [thunkMiddleware]
  const middleWareEnhancer = applyMiddleware(...middlewares)

  return createStore(rootReducer, composeWithDevTools(middleWareEnhancer))
}
